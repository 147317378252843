<template>
  <!-- 风控分配在处理数量 -->
  <div class="left_bottom riskControl">
    <title-name-slot title="分配风控后在处理单据统计"></title-name-slot>
    <!-- 图表数据 -->
    <div class="total" v-if="riskControlData.length > 0" :class="{openTotal: openFlag}">
      <i class="iconfont iconcz-hzb"></i>总计：{{total}}个
    </div>
    <div class="statistics chartsContent" id="echarts4"></div>
  </div>
</template>
<script>
import { statisticsApi } from '@/api/homeApi'
import titleNameSlot from './title-name-slot.vue'
export default {
  name: 'riskControlAllocation',
  components: { titleNameSlot },
  data () {
    return {
      riskControlData: [],
      myChart4: '',
      state: {
        aIntervalCountStr: '500万以下',
        bIntervalCountStr: '500万-999万',
        cIntervalCountStr: '1000万-2000万',
        dIntervalCountStr: '2000万以上'
      },
      total: 0
    }
  },
  computed: {
    openFlag () { return this.$store.state.sys.openFlag },
    api () {
      return statisticsApi
    }
  },
  mounted () {
    this.riskControlStatistics()
  },
  activated () {
    this.riskControlStatistics()
  },

  methods: {
    // 放款金额占比数据获取
    riskControlStatistics () {
      this.api.getRiskManagerAllocationCount().then(res => {
        if (res.success) {
          if (res.data) {
            for (const key in res.data) {
              this.riskControlData.push({
                name: this.state[key] + ' ' + ' ' + ' ' + ' ' + ' ' + ' ' + ' ' + ' ' + ' ' + res.data[key] + '个',
                value: res.data[key]
              })
              this.total = this.total + res.data[key]
            }
          }
          this.setecharts()
        }
      })
    },
    // 放款金额占比
    setecharts () {
      if (this.riskControlData.length === 0) {
        this.riskControlData = [{ value: 0, name: '暂无数据' }]
      }
      this.myChart4 = this.$echarts.init(document.getElementById('echarts4'))
      this.option = {
        // 自定义颜色
        color: [
          '#A5E7FC',
          '#FDBA9D',
          '#A2E9C4',
          '#FFDE95',
          '#FFDE95',
          '#ff5555',
          '#ff8c00',
          '#ffd700',
          '#90ee90',
          '#00ced1',
          '#1e90ff',
          '#c71585'
        ],
        tooltip: {
          trigger: 'item',
          formatter: (param) => {
            const html = param.name.replace(' ', ':')
            return html
          }
        },
        legend: {
          icon: 'circle',
          orient: 'vertical',
          top: '50px',
          left: '55%',
          color: '#D1B28C',
          textStyle: {
            color: '#D1B28C',
            fontSize: 12
          }
        },
        // 饼图配置
        series: [
          {
            name: 'decoration',
            type: 'pie',
            radius: ['35%', '70%'],
            avoidLabelOverlap: false,
            center: ['23%', '52%'],
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
                position: 'center'
              }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.riskControlData
          }
        ]
      }
      this.myChart4.setOption(this.option)
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
